var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "EditLayout",
        {
          attrs: {
            item: _vm.item,
            "submit-button-text": _vm.$t("save"),
            "supports-editor": false,
            photo: _vm.background,
            "supports-photo": "",
          },
          on: {
            submit: function ($event) {
              return _vm.edit()
            },
          },
        },
        [
          _c("div", { attrs: { slot: "middle" }, slot: "middle" }, [
            _c("section", [
              _c("h4", [_vm._v(_vm._s(_vm.$t("generalSettings")))]),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$t("sitesTitle"),
                      placeholder: _vm.$t("enterSitesTitle"),
                    },
                    model: {
                      value: _vm.item.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "name", $$v)
                      },
                      expression: "item.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$t("sitesSubtitle"),
                      placeholder: _vm.$t("enterSitesSubtitle"),
                    },
                    model: {
                      value: _vm.item.tagline,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "tagline", $$v)
                      },
                      expression: "item.tagline",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$t("systemEmail"),
                      placeholder: _vm.$t("enterSystemEmail"),
                      type: "email",
                    },
                    model: {
                      value: _vm.item.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "email", $$v)
                      },
                      expression: "item.email",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("section", [
              _c("h4", [_vm._v(_vm._s(_vm.$t("imageSettings")))]),
              _c("div", [
                _c("h5", [_vm._v(_vm._s(_vm.$t("miniPhotos")))]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("width"),
                        placeholder: _vm.$t("width"),
                      },
                      model: {
                        value: _vm.item.mini_width,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "mini_width", $$v)
                        },
                        expression: "item.mini_width",
                      },
                    }),
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("height"),
                        placeholder: _vm.$t("height"),
                      },
                      model: {
                        value: _vm.item.mini_height,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "mini_height", $$v)
                        },
                        expression: "item.mini_height",
                      },
                    }),
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("compression"),
                        placeholder: _vm.$t("setAValueBetween", {
                          min: 0,
                          max: 100,
                        }),
                        min: "0",
                        max: "100",
                      },
                      model: {
                        value: _vm.item.mini_compression,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "mini_compression", $$v)
                        },
                        expression: "item.mini_compression",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("h5", [_vm._v(_vm._s(_vm.$t("thumbPhotos")))]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("width"),
                        placeholder: _vm.$t("width"),
                      },
                      model: {
                        value: _vm.item.thumb_width,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "thumb_width", $$v)
                        },
                        expression: "item.thumb_width",
                      },
                    }),
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("height"),
                        placeholder: _vm.$t("height"),
                      },
                      model: {
                        value: _vm.item.thumb_height,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "thumb_height", $$v)
                        },
                        expression: "item.thumb_height",
                      },
                    }),
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("compression"),
                        placeholder: _vm.$t("setAValueBetween", {
                          min: 0,
                          max: 100,
                        }),
                        min: "0",
                        max: "100",
                      },
                      model: {
                        value: _vm.item.thumb_compression,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "thumb_compression", $$v)
                        },
                        expression: "item.thumb_compression",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("h5", [_vm._v(_vm._s(_vm.$t("mediumPhotos")))]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("width"),
                        placeholder: _vm.$t("width"),
                      },
                      model: {
                        value: _vm.item.md_width,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "md_width", $$v)
                        },
                        expression: "item.md_width",
                      },
                    }),
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("height"),
                        placeholder: _vm.$t("height"),
                      },
                      model: {
                        value: _vm.item.md_height,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "md_height", $$v)
                        },
                        expression: "item.md_height",
                      },
                    }),
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("compression"),
                        placeholder: _vm.$t("setAValueBetween", {
                          min: 0,
                          max: 100,
                        }),
                        min: "0",
                        max: "100",
                      },
                      model: {
                        value: _vm.item.md_compression,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "md_compression", $$v)
                        },
                        expression: "item.md_compression",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("h5", [_vm._v(_vm._s(_vm.$t("sliderLargePhotos")))]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("width"),
                        placeholder: _vm.$t("width"),
                      },
                      model: {
                        value: _vm.item.slider_width,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "slider_width", $$v)
                        },
                        expression: "item.slider_width",
                      },
                    }),
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("height"),
                        placeholder: _vm.$t("height"),
                      },
                      model: {
                        value: _vm.item.slider_height,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "slider_height", $$v)
                        },
                        expression: "item.slider_height",
                      },
                    }),
                    _c("BaseInput", {
                      staticClass: "col-sm-4",
                      attrs: {
                        type: "number",
                        label: _vm.$t("compression"),
                        placeholder: _vm.$t("setAValueBetween", {
                          min: 0,
                          max: 100,
                        }),
                        min: "0",
                        max: "100",
                      },
                      model: {
                        value: _vm.item.slider_compression,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "slider_compression", $$v)
                        },
                        expression: "item.slider_compression",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "section",
              [
                _c("BaseButton", { on: { click: _vm.migrateProdDbToDev } }, [
                  _vm._v(" " + _vm._s(_vm.$t("migrateProdDBToDev")) + " "),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
            [
              _c(
                "section",
                [
                  _c("LibraryModal", {
                    attrs: { photo: _vm.logo, label: _vm.$t("logo") },
                    on: { updatePhoto: _vm.updatePhoto },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }